import {
  addInputDefaultOptions,
  calcColumnWidth,
  containerWidthForElement,
  getPaneByElementType,
  isElementHiddenInView,
  isNano,
  selectedImage,
  TEMPLATE_FEATURES,
} from '@/editor/util';
import { showInitialScreenByUserType } from '@/router/utils';
import {
  capitalizeFirstLetter,
  codeInsertionStatus,
  mobileWidth,
  removeAccountIdFromCookie,
  setAccountIdInCookie,
  purifyDomain,
} from '@/util';
import { dropdownTypes, inputTypes } from '@om/editor-ssr-shared/src/utils';
import * as Cookies from 'js-cookie';
import { get, get as _get, setWith, debounce } from 'lodash-es';
import Vue from 'vue';
import Vuex from 'vuex';

import { apiBaseUrl } from '@/config/url';
// admin begin
import { apolloClient } from '@/apollo';
import { DefaultLayout as DefaultMockLayout } from '@/editor/components/MockSite.vue';
import ADD_FLAG_TO_ACCOUNT from '@/graphql/AddFlagToAccount.gql';
import CODE_INSERTED_IN_ALL_DOMAINS from '@/graphql/CodeInsertedInAllDomains.gql';
import FINISH_ONBOARDING from '@/graphql/FinishOnboarding.gql';
import GET_ACCOUNT from '@/graphql/GetAccount.gql';
import GET_AVAILABLE_ACCOUNTS from '@/graphql/GetAvailableAccounts.gql';
import GET_COUPON_COUNT from '@/graphql/GetCouponCount.gql';
import GET_DETAILED_DOMAINS from '@/graphql/GetDetailedDomains.gql';
import GET_OAUTH_TOKEN from '@/graphql/GetOAuthToken.gql';
import GET_PUBLIC_FEATURES from '@/graphql/GetPublicFeatures.gql';
import LOGOUT from '@/graphql/Logout.gql';
import REMOVE_AUTOMATIC_COUPON_CONFIG from '@/graphql/RemoveAutomaticCouponConfig.gql';
import REMOVE_AUTOMATIC_COUPON_CONFIG_FOR_VARIANT from '@/graphql/RemoveAutomaticCouponConfigForVariant.gql';
import REMOVE_FLAG_TO_ACCOUNT from '@/graphql/RemoveFlagToAccount.gql';
import SAVE_ONBOARDING_STAGE from '@/graphql/SaveOnboardingStage.gql';
import SET_AUTOMATIC_COUPON_CONFIG from '@/graphql/SetAutomaticCouponConfig.gql';
import STORE_INFO_BOX_VISIBILITY from '@/graphql/StoreInfoBoxVisibility.gql';
import TRACK_PAGEVIEW from '@/graphql/TrackPageview.gql';
import UPDATE_THEMEKIT from '@/graphql/UpdateThemeKit.gql';
import GET_POSSIBLE_FILTER_VALUES from '@/graphql/GetPossibleTemplateFilterValues.gql';
import GET_RECOMMENDED_USE_CASES_V2 from '@/graphql/GetRecommendedUseCasesV2.gql';
import CREATE_DC_CAMPAIGN_FROM_CACHE from '@/graphql/CreateDynamicContentCampaignFromCache.gql';
import UPSERT_GENERAL_SETTING from '@/graphql/UpsertGeneralSetting.gql';
import i18n from '@/i18n';
import router from '@/router/index';
import { SalesAdapter } from '@/services/salesAdapter';
import axios from 'axios';
import gql from 'graphql-tag';
import LogRocket from 'logrocket';
import moment from 'moment';

import { ROLES } from '@/config/roles';
import runtimeConfig from '@/config/runtime';
import editorOuter from '@/store/editorOuter';
import payment from '@/store/payment';
import productTour from '@/store/productTour';
import failedIntegrationResyncStorage from '@/services/integrations/resync/storage';
import { track } from '@/services/xray';

import {
  FEATURE_CONSTANTS,
  isFeatureEnabled,
  isOnboardingAiEnabled,
  hasCampaignShareFeature,
  isNewSiteDetectionEnabled,
  hasSmartNewCampaignFeature,
  hasSmartAbTestFeature,
  hasEditorBatchImageChangeFeature,
} from '@/utils/features';
import { getPaletteColors } from '@om/template-properties/src/getPaletteColors';
import {
  WIZARD_SETUP_KEY,
  PROFILE_KEY_ONBOARDING_DONE,
  PROFILE_KEY_AI_ONBOARDING_DONE,
} from './config/constants';
import { lsRetrieve, lsStore } from './helpers/localStorage';
import { addAppInstalledAgo, addProfileValues } from './shopifyUserInfos';
import { PartnerTypeService } from './services/partnerTypeService';

const RECENTLY_USED_COLOR_SUBSCRIBE = 'setRecentlyUsedColor';
const KEY_GENERAL_SETTING_IMAGE_REPLACE = 'imageReplace';

const getElement = (uid) => {
  return window.om.template.elements.find((e) => e.uid === uid);
};

// admin end
Vue.use(Vuex);

const frameStore = () => {
  const workspace = document.getElementById('workspaceFrame');
  if (!workspace) {
    return;
  }
  return workspace.contentWindow.om?.store;
};

const _clone = (v) => JSON.parse(JSON.stringify(v));
const _vm = () => window.om.store._vm; // webpack 4 this not working workaround
const nestedAccess = (target, property, value) => {
  if (property !== undefined) {
    const parts = property.split('.');
    const length = parts.length;
    let tmp = target;
    for (const [i, part] of parts.entries()) {
      if (i < length - 1) {
        if (tmp[part] === undefined) {
          if (value !== undefined) {
            _vm().$set(tmp, part, {});
          } else {
            return null;
          }
        }
        tmp = tmp[part];
      } else if (value !== undefined) {
        _vm().$set(tmp, part, value); // this.a wft??
      } else {
        return tmp[part];
      }
    }
  }
};

const _columnInfoOfSelectedElement = (state) => {
  const row = state.selectedRow;
  if (!row) return;
  const columns = row.columns;
  if (state.selectedElement && state.selectedElement.uid) {
    const uid = state.selectedElement.uid;
    for (let i = 0; i < columns.length; i++) {
      for (let j = 0; j < columns[i].elements.length; j++) {
        if (uid === columns[i].elements[j].uid) {
          return { row, y: i };
        }
      }
    }
  }
};

const _updateStyle = (state, property, value) => {
  const valuesArray = Array.isArray(value) ? value : [{ property, value }];
  for (const element of valuesArray) {
    nestedAccess(state, element.property, element.value);
    const stateAttr = element.property.split('.')[0];

    if (stateAttr === 'globalStyle') {
      window.om.bus.$emit('updateTemplateStyle', state.globalStyle);
    } else {
      const uid = state[stateAttr].uid;
      const deviceType = state.mobilePreview ? 'mobile' : 'desktop';
      const style = _clone(state[stateAttr][deviceType]);
      window.om.bus.$emit('updateStyle', { uid, style });
    }
  }
  window.om.bus.$emit('generateCss');
};

const _setLocale = (state, locale) => {
  if (locale) {
    state.locale = locale;
    i18n.locale = state.locale;
    moment.locale(state.locale);
    localStorage.setItem('locale', locale);
  }
};

const _activatePaneL2 = (state, panelName, vm, sameRow) => {
  const paneNames = ['PagePane', 'OverlayPane', 'TeaserPane', 'InputsPane', 'ScratchCardPane'];

  if (panelName === 'TeaserPane') {
    // state.teaserPreview = true
    // _vm().$bus.$emit('setTeaserPreview', true)
  }

  // if (state.mobilePreview) return
  // No In-Out animation: Selecting same row (switching column selection), Page, Overlay, Tab selection
  if (
    state.paneLevel !== 1 &&
    ((panelName === 'RowPane' && sameRow === true) ||
      (paneNames.includes(panelName) &&
        paneNames.includes(state.paneL2) &&
        panelName === state.paneL2) ||
      (Object.keys(state.validationError).length && panelName === state.paneL2))
  ) {
    return;
  }

  if (state.paneLevel !== 1) {
    state.paneLevel = 1;
  }

  state.paneLevel = 2;
  state.paneL2 = panelName;

  // hide colorpicker on scroll
  setTimeout(() => {
    const sidebarPane = document.querySelector('.sidebar-inner-box');
    if (sidebarPane) {
      sidebarPane.addEventListener('scroll', () => {
        state.colorPicker.show = false;
      });
    }
  }, 100);
};

const _emitLogin = (store) => {
  const opener = window.opener || window.parent;

  if (opener !== window) {
    if (localStorage.getItem('shoprenter_oauth') || localStorage.getItem('shopify_oauth')) {
      store.dispatch('oauthLogin');
    }

    opener.postMessage(JSON.stringify({ type: 'optimonk', action: 'login' }), '*');
  }
};

const isExpired = () => {
  const expiration = localStorage.getItem('cookieExpiration');
  const res =
    expiration !== null && expiration !== 'null'
      ? moment(expiration, 'YYYY-MM-DD').isBefore(moment())
      : true;
  return res;
};

const getCleanDomain = (domainString) => {
  return typeof domainString === 'string' && domainString.replace('www.', '').toLowerCase();
};

const _saveThemeKit = async (themeKit, oldName) => {
  return apolloClient.mutate({
    mutation: UPDATE_THEMEKIT,
    variables: {
      name: themeKit.name,
      value: themeKit,
      oldName,
    },
  });
};

const _colorPickerCommonTopPosition = (top, hasTopLine = true) => {
  const height = hasTopLine ? 650 : 560;
  const calculatedTop = window.innerHeight - top < height ? 10 : top;

  return calculatedTop <= 0 ? 10 : calculatedTop;
};

const W_PX_COLOR_PICKER = 310;
const W_PX_COLOR_PICKER_PAD = 10;

const _colorPickerInlinePosition = ({ top: elementTop, left: elementLeft, isMobilePreview }) => {
  const isGlobalsOpen = !!document.querySelector('.globals-opened');
  const calculatedLeft = elementLeft - (!isGlobalsOpen ? 268 : 0);
  let left = calculatedLeft <= 0 ? W_PX_COLOR_PICKER_PAD : calculatedLeft;

  if (isMobilePreview) {
    const mobileWrapperRect = document.querySelector('.om-workspace').getBoundingClientRect();
    const calculatedLeft =
      mobileWrapperRect.left - W_PX_COLOR_PICKER < 0
        ? W_PX_COLOR_PICKER_PAD
        : mobileWrapperRect.left - 295;
    left = calculatedLeft;
  }

  return { top: _colorPickerCommonTopPosition(elementTop), left };
};

const setBusinessType = (business) => {
  try {
    let visitorAttributes = localStorage.getItem('OptiMonkVisitorAttributes');
    if (!visitorAttributes) {
      visitorAttributes = {};
    } else {
      visitorAttributes = JSON.parse(visitorAttributes);
    }
    visitorAttributes.business_type = business;

    localStorage.setItem('OptiMonkVisitorAttributes', JSON.stringify(visitorAttributes));
  } catch {}
};

function getColorPickerComponent(component, property, state) {
  if (property.startsWith('globalStyle.palette.')) {
    return property.split('.').slice(-1)[0];
  }
  if (property.startsWith('overlay.')) return 'website-overlay';
  if (property.startsWith('closeButton.')) return 'close-button';
  if (property === 'omcolor') return 'WYSIWYG';
  if (component === 'global') {
    const isTeaser = (state.selectedPage && state.selectedPage.isTeaser) || state.teaserPreview;
    return isTeaser ? 'teaser' : 'page';
  }
  return component ?? 'unknown';
}

const HEAP_EVENT_NAME_EDITOR_CHANGE = 'test-ChangeEditorSetting';

const adminInitialState = {
  showAdminLoader: true,
  deviceSelectorList: {
    show: false,
    position: { top: 0, left: 0 },
  },
  isLoading: false,
  isEditor: false,
  device: 'other',
  table: {
    sorting: null,
    pagination: null,
  },
  locale: 'en',
  loggedIn: false,
  impersonate: false,
  affiliateImpersonate: false,
  accountType: '',
  pendingShopifyInstall: null,
  currentPlan: '',
  currentPeriod: '',
  showBankTransferToBraintreeNotification: false,
  showBankTransferNotification: false,
  account: {
    campaignInfos: { campaignCount: 0, totalConversions: 0 },
    billing: { period: null, package: '', dateExpires: '', datePaid: '' },
    login: { firstName: '', lastName: '', email: '', region: '' },
    settings: {
      onboarding: {
        campaignCreated: null,
        finished: null,
        stage: null,
      },
      shops: [],
      domains: [],
      affiliate: {
        partnerInfo: {
          displayName: '',
          slug: '',
          headline: '',
          buttonText: '',
          buttonUrl: '',
          about: '',
          image: '',
        },
      },
    },
    locale: '',
    type: '',
    partnerType: '',
    businessName: '',
    name: '',
    limits: { campaigns: 10, domains: 10, maxVisitor: 0, usedVisitor: 0, isOverrun: false },
    createdAt: 0,
    profile: {
      firstConversion: null,
    },
  },
  sharedAccounts: [],
  embedded: false,
  userInfoMessage: null,
  isCodeInserted: false,
  whiteLabelSettings: null,
  loadingTransitionRef: null,
  teaserPage: null,
  showUniversalsOnChooser: false,
  preferredTemplateLanguage: 'en',
  templateChooser: {
    filterValues: null,
    baseThemeKitIds: [],
  },
  recommendedTactics: [],
  partnerTypeService: null,
  hideStickyBar: false,
  template: null,
};

const editorInitialState = {
  modifiedBrandColors: [],
  universal: false,
  isCustomThemeNameError: false,
  themeKit: {
    name: '',
    rounding: '',
    fonts: [],
  },
  sourceThemeKit: {
    name: '',
    rounding: '',
    fonts: [],
  },
  savedThemeKit: {
    name: '',
    rounding: '',
    fonts: [],
    colors: [],
  },
  elementStylesChanged: false,
  themekitChanged: false,
  isThemekitHidden: false,
  globalStyle: {
    palette: {
      mainColor: '',
      secondaryColors: [],
      themeColors: [],
    },
    brandSettings: {
      brandFont: '',
      brandRadius: '',
    },
    text: {},
    button: {},
    image: {},
    overlay: {},
    inputs: {},
    mode: '',
    data: {},
  },
  page: {},
  paneL2: null,
  paneL3: null,
  paneLevel: 1,
  selectedPage: null,
  selectedColumn: null,
  selectedElement: null,
  selectedRow: null,
  colorPicker: {
    show: false,
    left: '400px',
    top: '300px',
    property: 'globalStyle.palette.mainColor',
    palette: true,
    source: null,
    value: null,
    recentlyUsedColor: lsRetrieve('recentlyUsedColors'),
    onlySolid: false,
    index: null,
    colorInstance: null,
    component: null,
  },
  calendar: {
    visible: false,
  },
  showImageManager: false,
  showImageManagerV2: false,
  imageCropper: {
    show: false,
    actionLocation: null,
    colorInstance: null,
  },
  selectedProductIndex: 0,
  selectedPath: 'desktop',
  imageManagerDestination: {},
  imageManagerLogoUpload: false,
  imageDropColumn: { row: null, y: null },
  showFormManager: {
    firstStep: false,
    secondStep: false,
    thirdStep: false,
    lumiraSettings: false,
    couponSettings: false,
    deleteFields: false,
    manageField: false,
    inputValidations: false,
    scratchCardSettings: false,
    scratchCardTest: false,
    customHTMLSettings: false,
    missingCustomFields: false,
    pickAPresentSettings: false,
    pickAPresentTest: false,
    couponCreate: false,
    previewMissing: false,
    previewOld: false,
    addNewStyle: false,
    editCustomStyle: false,
    deleteCustomStyle: false,
  },
  selectedElementValue: '',
  inputFields: [],
  createDiscountProps: {},
  formModalMetaData: {},
  showCustomEditorBox: false,
  customCss: '',
  customJs: '',
  customJsByEvents: {
    pageLoad: '',
    popupShow: '',
    popupFill: '',
    popupReject: '',
  },
  images: [],
  inputs: [],
  wheelOptions: [],
  scratchCardOptions: [],
  pickAPresentOptions: [],
  saveCompleted: true,
  campaign: {},
  lastEditedCampaignData: {},
  isActiveUndo: false,
  isActiveRedo: false,
  mobilePreview: false,
  templateHash: null,
  templateSaveData: {},
  validationError: {},
  openCloseButton: false,
  hasWheel: true,
  wheelPageNumber: 0,
  hasFeedbackOnPage: true,
  hasRadioFeedback: true,
  fonts: null,
  teaserPreview: false,
  pages: [],
  floatingActionsPosition: 'right',
  hasCouponOnPage: false,
  mockSiteLayout: DefaultMockLayout,
  maxPageHeight: null,
  loadedFonts: [],
  couponCounts: {
    available: 0,
    shown: 0,
    reserved: 0,
  },
  productDetailsModal: {
    image: {
      imageId: null,
      imageUrl: null,
    },
    name: '',
    sku: '',
    price: '',
    oldPrice: '',
    url: '',
  },
  inlineSvgs: {},
};

const _setAccountFeaturesInWorkspace = (features) => {
  _vm().$bus.$emit('setAccountFeatures', features);
};

const _debouncedUpsertGeneralSetting = debounce(function (key, value) {
  apolloClient.mutate({
    mutation: UPSERT_GENERAL_SETTING,
    variables: { key, value },
  });
}, 300);

const store = new Vuex.Store({
  modules: {
    payment,
    productTour,
    editorOuter,
  },
  state: _clone({ ...adminInitialState, ...editorInitialState }),
  getters: {
    isThemeKit: (state) => {
      return !!state.themeKit?.name;
    },
    getCustomThemeKitValues: (state) => {
      return state.themeKit;
    },
    // admin begin
    isNewTemplate: () => {
      return window.location.pathname.includes('/new');
    },
    template: (state) => {
      return state.template ?? false;
    },
    isManagedReferral: (state) => {
      const currentPlan =
        _get(state, 'account.billing.futurePackage') || _get(state, 'account.billing.package', '');
      return currentPlan && currentPlan.includes('MANAGED_REFERRAL');
    },
    isDowngradePending: (state) => _get(state, 'account.settings.downgrade.pending', false),
    getDowngradeInformation: (state) => {
      const downgrade = _get(state, 'account.settings.downgrade', null);
      return downgrade.to ? downgrade : null;
    },
    domains: (state) => {
      return state.account.settings.domains;
    },
    userProfileDoneValue: (state) => {
      return get(state.account, `profile.${PROFILE_KEY_ONBOARDING_DONE}`, false);
    },
    userProfileAiDoneValue: (state) => {
      return get(state.account, `profile.${PROFILE_KEY_AI_ONBOARDING_DONE}`, false);
    },
    isTaskListCompleted: (_, getters) => {
      const userProfileDoneValue = getters.userProfileDoneValue;
      if (typeof userProfileDoneValue === 'string') {
        const dayDiff = moment().utc().diff(moment(userProfileDoneValue).utc(), 'hours');
        return dayDiff >= 24;
      }
      return userProfileDoneValue === true;
    },
    isUserRequestedAi: (state) => {
      const profile = _get(state, 'account.profile') || {};
      return Object.keys(profile).some((key) => key.includes('aiRequest'));
    },
    activeDomains: (state) => {
      return state.account.settings.domains.filter(({ inactive = false }) => inactive !== true);
    },
    activeDomainsCount: (state, getters) => getters.activeDomains.length,
    isLoggedIn: (state) => () => !isExpired() && state.loggedIn,
    getAccountName: (state) => state.account.name,
    getAccountBusinessName: (state) => state.account.businessName,
    getAccountEmail: (state) => state.account.login.email,
    getLoginFirstName: (state) => state.account.login.firstName,
    getLoginLastName: (state) => state.account.login.lastName,
    getPendingShopifyInstall: (state) => state.pendingShopifyInstall,
    userRole: (state) => state.account.userRole,
    isOwner: (state) => state.account.userRole === ROLES.OWNER,
    isSuperAdmin: (state) => state.account.isSuperAdmin,
    getExportEmailAddress: (_, getters) =>
      getters.isSuperAdmin ? 'optimonkdev@optimonk.com' : getters.getAccountEmail,
    currentPlan: (state) => {
      const currentPlan = _get(state, 'account.billing.package', '') || '';
      return currentPlan.toUpperCase();
    },
    accountProfile: (state) => {
      return _get(state, 'account.profile');
    },
    getRecommendedPlan: (state) => {
      return _get(state, 'account.profile.recommendedPlan.recommendation', '') || '';
    },
    getRecommendedPlanRunOuts: (state) => {
      return _get(state, 'account.profile.recommendedPlan.runOuts', []) || [];
    },
    hasCreatedFollowup: (state) => {
      return _get(state, 'account.profile.firstFollowupCreated');
    },
    hasCreatedExperiment: (state) => {
      return _get(state, 'account.profile.firstExperimentCreated');
    },
    integrations: (state) => _get(state, 'account.settings.integrations', []),
    currentPeriod: (state) => state.account.billing.period,
    getRegion: (state) => _get(state, 'account.login.region'),
    selectedPlan: (state) => state.selectedPlan,
    selectedPeriod: (state) => state.selectedPeriod,
    getLocale: (state) => _get(state, 'locale'),
    isPaying: (state, getters) => {
      const accPackage = _get(state, 'account.billing.package');
      return getters.isPayingBy(accPackage);
    },
    isPayingBy: () => (plan) => {
      return plan && ['trial', 'belso', 'free', 'demo'].indexOf(plan.toLowerCase()) === -1;
    },
    isFreeOrTrial: (state) => {
      const accPackage = _get(state, 'account.billing.package');
      return accPackage && ['free', 'trial'].indexOf(accPackage.toLowerCase()) !== -1;
    },
    hasActiveSubscription: (state, getters) => {
      const expired =
        moment(_get(state, 'account.billing.dateExpires'))
          .startOf('day')
          .subtract(14, 'days')
          .diff(moment().startOf('day'), 'days') <= 0;

      return getters.isPaying && !expired;
    },
    getDateExpires: (state) => {
      return _get(state, 'account.billing.dateExpires');
    },
    isAccountExpired: (state) => {
      const expires = _get(state, 'account.billing.dateExpires');
      try {
        const expired = moment(expires);
        return expired.diff(moment(), 'days') < 0;
      } catch (e) {
        console.error('Invalid expires date');
      }
      return false;
    },
    cancelledPlan: (state) => _get(state, 'account.billing.cancelledAt'),
    spamProtection: (state) => _get(state, 'account.settings.spamProtection', false),
    hasAnyDomainBeenRequested: (state) => {
      if (state.isCodeInserted) {
        return true;
      }

      const domains = _get(state, 'account.settings.domains');
      if (!domains) return false;

      const domainWithRequest = domains.find(
        (d) => d.lastRequestDate !== null || d.v3LastRequestDate !== null,
      );
      return !!domainWithRequest;
    },
    domainRequestStatus: (state) => (domainName) => {
      const domains = _get(state, 'account.settings.domains');
      if (!domains) return 'no_code';

      const domain = domains.find((d) => d.domain === domainName);

      return codeInsertionStatus(domain);
    },
    isTemplateAuthor: (_, getters) => getters.hasAccountFeature(FEATURE_CONSTANTS.TEMPLATE_AUTHOR),
    isRealTemplateAuthor: (state, getters) => {
      const hasAuthorFlag = getters.isTemplateAuthor;
      return hasAuthorFlag && state.account.databaseId === 44;
    },
    isAffiliate: (state) => {
      const settings = _get(state, 'account.settings.general');
      if (!settings) return false;
      const affiliateSetting = settings.find((e) => e.key === 'affiliate');
      return (
        affiliateSetting &&
        (affiliateSetting.value === '1' ||
          affiliateSetting.value === 'true' ||
          affiliateSetting.value === true)
      );
    },
    isFreemium: (_, getters) =>
      getters.currentPlan && ['FREE', 'ESSENTIAL'].includes(getters.currentPlan),
    isPageViewBasedPackageByPlan: () => (plan) => {
      if (plan) {
        return (
          ['FREE', 'DEMO', 'ESSENTIAL', 'GROWTH', 'PREMIUM'].includes(plan) ||
          plan.indexOf('MASTER') === 0
        );
      }
      return false;
    },
    isPageViewBasedPackage: (_, getters) => {
      return getters.isPageViewBasedPackageByPlan(getters.currentPlan);
    },
    isFreemiumDemo: (_, getters) => {
      return getters.currentPlan && getters.currentPlan === 'DEMO';
    },
    isFreemiumMaster: (_, getters) => {
      return getters.currentPlan && getters.currentPlan.indexOf('MASTER') > -1;
    },
    isEnterprise: (_, getters) => {
      return getters.currentPlan && getters.currentPlan.indexOf('ENTERPRISE') > -1;
    },
    // admin end
    nestedAccess: (state) => (property) => nestedAccess(state, property),
    isNano: (state) => isNano(state.globalStyle.mode),
    isSidebar: (state) => state.globalStyle.overlay.position !== 5,
    isSidebarOnMobile: (state) => state.globalStyle.overlay.position !== 5 && state.mobilePreview,
    isFullHeightSidebar: (state) => state.globalStyle.overlay.fitToScreen === true,
    isInterstitial: (state) => state.globalStyle.mode === 'interstitial',
    isFullscreen: (state) => state.globalStyle.mode === 'fullscreen',
    isEmbedded: (state) => state.globalStyle.mode === 'embedded',
    isTemplateEditorMode: (state) =>
      state.templateSaveData.type === undefined || state.templateSaveData.type === 'base',
    isTabSelected: (state) => state.teaserPreview,
    columnInfoOfSelectedElement: (state) => _columnInfoOfSelectedElement(state),
    elementMaxWidth: (state) => {
      const columnInfo = _columnInfoOfSelectedElement(state);
      if (columnInfo) {
        return calcColumnWidth(columnInfo.row, columnInfo.y);
      }

      return 0;
    },
    imageMaxSize: (state) => {
      const { selectedElement } = state;
      if (selectedElement) {
        const image = selectedImage(state);
        const imageWidth = _get(image, 'width') || 640;
        const colWidth = containerWidthForElement(
          selectedElement,
          undefined,
          state.mobilePreview ? 'mobile' : 'desktop',
        );
        return Math.max(imageWidth, colWidth);
      }

      return 0;
    },
    selectedImage: (state) => selectedImage(state),
    lastEditedData: (state) => state.lastEditedCampaignData,
    outOfVisitors: (state, getters) => {
      if (!state.account || !state.account.limits) return false;

      const used = getters.isPageViewBasedPackage
        ? state.account.limits.pageViews
        : state.account.limits.usedVisitor;
      const max = getters.isPageViewBasedPackage
        ? state.account.limits.maxPageViews
        : state.account.limits.maxVisitor;

      return used >= max;
    },
    shopSettings: (state) => _get(state, 'account.settings.shops', []),
    shopSettingsWithDomain: (state, getters) => {
      return getters.shopSettings.reduce((shopSettings, shop) => {
        if (`${shop.active}` === '0' || shop.inactive === false) return shopSettings;
        const domain = getters.domainByShopSetting(shop);

        if (domain) {
          shopSettings.push({
            ...shop,
            domain,
          });
        }

        return shopSettings;
      }, []);
    },
    shopRenterAppSettings: (_, getters) =>
      getters.shopSettings.filter((s) => s.type === 'shoprenter'),
    shopifyAppSettings: (_, getters) => getters.shopSettings.filter((s) => s.type === 'shopify'),
    hasActiveShop: (_, getters) => (type) =>
      getters.shopSettings &&
      getters.shopSettings.some(
        (s) => s.type === type && (parseInt(s.active, 10) === 1 || s.active === true),
      ),
    hasActiveShopify: (_, getters) => getters.hasActiveShop('shopify'),
    hasActiveShopRenter: (_, getters) => getters.hasActiveShop('shoprenter'),
    hasShopify: (_, getters) =>
      getters.shopSettings && getters.shopSettings.some((s) => s.type === 'shopify'),
    hasShopRenter: (_, getters) =>
      getters.shopSettings && getters.shopSettings.some((s) => s.type === 'shoprenter'),
    hasShopifyTrial: (_, getters) =>
      getters.shopSettings.some(
        (shop) => shop.type === 'shopify' && shop.plan_name.includes('trial'),
      ),
    hasCustomDomain: (state, getters) => {
      const domains = _get(state, 'account.settings.domains', []);
      if (domains.length === 0) {
        return true;
      }
      return domains.some((d) => {
        return !(
          getters.isActiveShopifyDomain(d.domain) ||
          getters.isActiveShoprenterDomain(d.domain) ||
          getters.isActiveWordpressDomain(d.domain)
        );
      });
    },
    isKlaviyoDomain: (state) => (domainString) => {
      const domains = _get(state, 'account.settings.domains', []);
      return domains.some((domain) => domain.domain === domainString && domain.isKlaviyoDetected);
    },
    isActiveShopifyDomain: (state, getters) => (domainString) => {
      const domains = _get(state, 'account.settings.domains', []);
      const domain = domains.find((d) => d.domain === domainString);

      if (domain && domain.platform === 'shopify' && domain.shopId) {
        return getters.shopSettings.some(
          (s) =>
            s.type === 'shopify' &&
            parseInt(s.active, 10) === 1 &&
            s.myshopify_domain === domain.shopId,
        );
      }

      return getters.shopSettings.some(
        (s) =>
          s.type === 'shopify' &&
          parseInt(s.active, 10) === 1 &&
          s.live_domain.indexOf(getCleanDomain(domainString)) === 0,
      );
    },
    isActiveShoprenterDomain: (state, getters) => (domainString) => {
      const domains = _get(state, 'account.settings.domains', []);
      const domain = domains.find((d) => d.domain === domainString);

      if (domain && domain.platform === 'shoprenter' && domain.shopId) {
        return getters.shopSettings.some(
          (s) => s.type === 'shoprenter' && s.active === true && s.shopname === domain.shopId,
        );
      }

      return getters.shopSettings.some((s) => {
        if (s.type === 'shoprenter' && (s.active === true || parseInt(s.active, 10) === 1)) {
          if (s.domains && s.domains.length) {
            return s.domains.some(
              (srDomain) =>
                `${srDomain.domain.replace('www.', '')}`.toLowerCase() ===
                getCleanDomain(domainString),
            );
          }

          return domainString.indexOf(s.shopname) !== -1;
        }

        return false;
      });
    },
    shopSettingsByDomain: (state, getters) => (domainString) => {
      const domains = _get(state, 'account.settings.domains', []);
      const domain = domains.find((d) => d.domain === domainString);

      if (domain && domain.shopId) {
        /* eslint-disable camelcase */
        return getters.shopSettings.find(
          ({ shopname, myshopify_domain }) =>
            shopname === domain.shopId || myshopify_domain === domain.shopId,
        );
        /* eslint-enable camelcase */
      }

      const cleanDomain = getCleanDomain(domainString);

      // falback, we should identify shop entries by the shopId field of domains in the future
      return getters.shopSettings.find((s) => {
        if (s.type === 'shoprenter') {
          if (s.domains && s.domains.length) {
            return s.domains.some((srDomain) => `${srDomain.domain}`.toLowerCase() === cleanDomain);
          }

          return cleanDomain.indexOf(s.shopname) !== -1;
        }
        if (s.type === 'shopify') {
          return (
            s.live_domain.toLowerCase().indexOf(cleanDomain) !== -1 ||
            s.myshopify_domain.toLowerCase().indexOf(cleanDomain) !== -1
          );
        }

        return false;
      });
    },
    domainByShopSetting: (state, getters) => (shop) => {
      return getters.domains.find((domain) => {
        const cleanDomain = getCleanDomain(domain.domain);
        if (shop.type === 'shoprenter' && domain.platform === 'shoprenter' && domain.shopId) {
          return shop.domains?.some((srDomain) => getCleanDomain(srDomain.domain) === cleanDomain);
        }
        if (shop.type === 'shopify') {
          return (
            getCleanDomain(shop.live_domain).indexOf(cleanDomain) !== -1 ||
            getCleanDomain(shop.myshopify_domain).indexOf(cleanDomain) !== -1
          );
        }

        return false;
      });
    },
    shouldEnableShopifyAppEmbedForDomain: (state, getters) => (domain) => {
      const shopSettings = getters.shopSettingsByDomain(domain.domain);

      return (
        shopSettings?.active &&
        shopSettings?.type === 'shopify' &&
        shopSettings?.isShopifyAppExtensionActive &&
        !domain.v3LastRequestDate
      );
    },
    myshopifyDomainOfDomain: (state, getters) => (domainString) => {
      const shop = getters.shopSettingsByDomain(domainString);

      return shop?.myshopify_domain ?? null;
    },
    isShopifyDomain: (state, getters) => (domain, shopId) => {
      return getters.shopSettings.some(
        (s) =>
          s.type === 'shopify' &&
          (purifyDomain(s.live_domain) === purifyDomain(domain) || s.myshopify_domain === shopId),
      );
    },
    isShoprenterDomain: (state, getters) => (domainString) => {
      return getters.shopSettings.some(
        (s) =>
          s.type === 'shoprenter' &&
          s?.domains?.some((d) => purifyDomain(d.domain) === purifyDomain(domainString)),
      );
    },
    isActiveWordpressDomain: (state, getters) => (domain) =>
      getters.shopSettings.some(
        (s) => s.type === 'wordpress' && s.wordpress_domain.indexOf(domain) !== -1,
      ),
    hasOnlyShopifyDomain: (state, getters) => {
      const domains = _get(state, 'account.settings.domains', []);
      if (domains.length === 0) {
        return false;
      }
      return domains.every((d) => getters.isActiveShopifyDomain(d.domain));
    },
    getActiveShopifyShopSettings: (state, getters) => {
      return getters.shopSettings ? getters.shopSettings.filter((s) => s.active === 1) : [];
    },
    getActiveShopifyDomains: (_, getters) => {
      return getters.getActiveShopifyShopSettings.map((s) => ({
        liveDomain: s.live_domain,
        myShopifyDomain: s.myshopify_domain,
        shopifyId: s.shopify_id,
      }));
    },
    totalConversions: (state) => state.account.campaignInfos.totalConversions,
    installedFonts: (state) => {
      const fonts = [];
      if (!state.fonts) return fonts;
      Object.keys(state.fonts).forEach((key) => {
        const font = state.fonts[key];
        if (font.preInstalled || font.installedSubsets || font.custom) {
          fonts.push({
            key,
            ...font,
          });
        }
      });
      fonts.sort((a, b) => a.family.toLowerCase().localeCompare(b.family.toLowerCase()));
      return fonts;
    },
    campaignCreated: (state) => state.account.settings.onboarding.campaignCreated !== false,
    pageCount: (state) => state.pages.length,
    pages: (state) => state.pages,
    isSubUser: (state) => state.accountType === 'sub',
    isAgencyUser: (state) => state.accountType === 'agency',
    isNormalUser: (state) => state.accountType === 'normal',
    interestedInAiFeatures: (state) => state.account.profile?.interestedFeature === 'ai',
    isAccountFlaggedAsAgency: (state) => state.account.profile?.agencyPotential === true,
    whiteLabelling: (state) => state.whiteLabelSettings,
    brandName: (state) =>
      state.whiteLabelSettings ? state.whiteLabelSettings.brandName || 'OptiMonk' : 'OptiMonk',
    domainBase: (state) =>
      state.whiteLabelSettings
        ? state.whiteLabelSettings.domainBase || 'optimonk.com'
        : 'optimonk.com',
    customLogo: (state) =>
      state.whiteLabelSettings ? state.whiteLabelSettings.customLogo || null : null,
    customSquaredLogo: (state) =>
      state.whiteLabelSettings ? state.whiteLabelSettings.customSquared || null : null,
    databaseId: (state) => state.account.databaseId,
    onboardingFinished: (state) =>
      [true, null].includes(state.account.settings.onboarding.finished),
    hasSharedAccounts: (state) => state.sharedAccounts.length > 1,
    getAvatarUrl: (state) => {
      let avatar = '';
      if (state.account.login.socialLogins) {
        for (const s of state.account.login.socialLogins) {
          if (s.data.avatar) {
            avatar = s.data.avatar;
            break;
          }
        }
      }
      return avatar;
    },
    isAgencyImpersonate: (state) => {
      // is in sub account with owner role (the agency)
      return (
        state.affiliateImpersonate ||
        (state.impersonate === true && state.accountType === 'sub' && state.account.agencyUserRole)
      );
    },
    isImpersonate: (state) => {
      // is in shared account with write role
      return state.impersonate === true && state.accountType !== 'sub';
    },
    campaignInnerId: (state) => state.campaign.campaignInnerId,
    campaignId: (state) => state.campaign.campaignId,
    isMobile: (state) => state.device === 'mobile',
    selectedPageIndex: (state) => state.pages.findIndex((p) => p.uid === state.selectedPage.uid),
    isSteveMode: (_, getters) => getters.databaseId === 44 && getters.isTemplateEditorMode,
    teaserPage: (state) => state.teaserPage,
    hasTemplateFeature: () => (given) => {
      const store = frameStore();
      if (!store) return false;

      const features = store.state.template.features;

      return features && Array.isArray(features) && features.includes(given);
    },
    getTeaserPosition: (_, getters) => () => {
      const store = frameStore();
      if (!store) return false;

      if (getters.hasTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER)) {
        const teaser = store.state.template.elements.find((v) => v.type === 'OmPage' && v.isTeaser);
        return teaser.data.position;
      }
      return store.state.template.style.tab.position;
    },
    isTeaserPage: (state) =>
      (state.selectedPage && state.selectedPage.isTeaser) || state.teaserPreview,
    hasAccountFeature: (_, getters) => (given) => isFeatureEnabled(getters.accountFeatures, given),
    isUniversal: (state) => state.universal,
    accountFeatures: (state) => _get(state, 'account.features', []),
    preventSubscriberDataStorage: (state) =>
      _get(state, 'account.settings.preventSubscribe.storage'),
    wizardPreferences: (state) => _get(state, 'account.settings.wizardPreferences'),
    getUTMParams: (state) => state.utmParams,
    modifiedElements: () => frameStore().state.customTheme.modifiedElements,
    isOnboardingAiEnabled: (_, getters) => isOnboardingAiEnabled(getters.accountFeatures),
    isNewCampaign: () => {
      const store = frameStore();
      if (!store) return false;

      return store.getters.isNewCampaign;
    },
    templateChooserFilterValues: (state) => state.templateChooser?.filterValues,
    baseThemeKitIds: (state) => state.templateChooser?.baseThemeKitIds ?? [],
    getRecommendedTactics: (state) => state.recommendedTactics,
    hasCampaignShareFeature: (_, getters) => hasCampaignShareFeature(getters.accountFeatures),
    isNewSiteDetectionEnabled: (_, getters) => isNewSiteDetectionEnabled(getters.accountFeatures),
    hasSmartNewCampaignFeature: (_, getters) => hasSmartNewCampaignFeature(getters.accountFeatures),
    hasSmartAbTestFeature: (_, getters) => hasSmartAbTestFeature(getters.accountFeatures),
    hasEditorBatchImageChange: (_, getters) =>
      hasEditorBatchImageChangeFeature(getters.accountFeatures),
    imageReplaceValue(state) {
      const setting =
        state?.account?.settings?.general?.find?.(
          ({ key }) => key === KEY_GENERAL_SETTING_IMAGE_REPLACE,
        ) ?? {};
      return setting?.value ? setting?.value === '1' : true;
    },
  },
  mutations: {
    setImageReplaceValue(state, value) {
      const settingIndex = state?.account?.settings?.general?.findIndex?.(
        ({ key }) => key === KEY_GENERAL_SETTING_IMAGE_REPLACE,
      );
      const stringValue = value ? '1' : '0';

      if (settingIndex > -1) {
        state.account.settings.general[settingIndex].value = stringValue;
        return;
      }

      // settings.general always should be an array
      state?.account?.settings?.general?.push?.({
        key: KEY_GENERAL_SETTING_IMAGE_REPLACE,
        value: stringValue,
      });
    },
    setTemplate(state, template) {
      state.template = template;
    },
    setDomains(state, domains) {
      state.account.settings.domains = domains;
    },
    setRecommendedTactics(state, tactics) {
      state.recommendedTactics = tactics;
    },
    setCampaign: (state, campaign) => {
      state.campaign = campaign;
    },
    setThemeKitSettings(state, themeKitAttributes) {
      state.globalStyle.palette = themeKitAttributes.colors;
      state.themeKit = themeKitAttributes;
    },
    setSourceThemeKit(state, sourceThemeKit) {
      state.sourceThemeKit = sourceThemeKit;
    },
    setSavedThemeKitSettings(state, themeKitAttributes) {
      state.savedThemeKit = themeKitAttributes;
    },
    updateGlobalStyle(state, { property, value }) {
      _updateStyle(state, property, value);
    },
    setElementStylesChanged(state, value) {
      state.elementStylesChanged = value;
    },
    setThemekitChanged(state, value) {
      state.themekitChanged = value;
    },
    setIsThemekitHidden(state, value) {
      state.isThemekitHidden = value;
    },
    // admin begin
    resetTable(state) {
      state.table = { sorting: null, pagination: null };
    },
    showAdminLoader(state, show) {
      const ANIMATION_DELAY = 0;
      try {
        if (state.loadingTransitionRef) {
          if (state.loadingTransitionRef.classList) {
            const navbar = document.querySelector('.brand-side-nav');
            const inEditor = ['variant_edit', 'template_editor'].includes(router.currentRoute.name);
            if (navbar && !mobileWidth(window.innerWidth)) {
              state.loadingTransitionRef.classList.add('brand-loading-transition-nav-width');
            } else {
              state.loadingTransitionRef.classList.remove('brand-loading-transition-nav-width');
            }
            state.loadingTransitionRef.classList.remove('om-fadeIn', 'om-fadeOut');

            if (show) {
              if (inEditor) {
                state.loadingTransitionRef.classList.add('brand-loading-transition-editor');
              } else {
                state.loadingTransitionRef.classList.remove('brand-loading-transition-editor');
              }
              state.loadingTransitionRef.style.display = 'block';
            } else {
              setTimeout(() => {
                state.loadingTransitionRef.classList.add('om-fadeOut');

                const hide = (e) => {
                  if (!show) {
                    state.loadingTransitionRef.style.display = 'none';
                  }
                  e.target.removeEventListener('animationend', hide);
                };

                state.loadingTransitionRef.addEventListener('animationend', hide);
              }, ANIMATION_DELAY);
            }
          } else if (show) {
            state.loadingTransitionRef.style.display = 'block';
          } else {
            setTimeout(() => {
              state.loadingTransitionRef.style.display = 'none';
            }, ANIMATION_DELAY);
          }
        }
      } catch (err) {
        console.error(err);
      }
      state.showAdminLoader = show;
    },
    setPendingShopifyInstall(state, pendingShopifyInstall) {
      state.pendingShopifyInstall = pendingShopifyInstall;
    },
    updateTableSorting(state, sorting) {
      state.table.sorting = sorting;
    },
    updateTablePagination(state, pagination) {
      state.table.pagination = pagination;
    },
    setProfileKey(state, { key, value }) {
      if (state.account.profile) {
        Vue.set(state.account.profile, key, value);
      } else {
        Vue.set(state.account, 'profile', { [key]: value });
      }
    },
    removePage(state, page) {
      const index = state.pages.findIndex((p) => p.uid === page.uid);
      state.pages.splice(index, 1);
    },
    autoLogin(state) {
      const locale =
        localStorage.getItem('locale') !== null ? localStorage.getItem('locale') : 'en';
      if (!isExpired() && locale) {
        state.loggedIn = true;
        state.impersonate = localStorage.getItem('impersonate') === 'true';
        state.affiliateImpersonate = localStorage.getItem('affiliateImpersonate') === 'true';
        _setLocale(state, locale);

        _emitLogin(this);
      }
    },
    logout(state, redirect = true) {
      // apolloClient.cache.reset()
      // i18n.locale = state.locale
      localStorage.removeItem('cookieExpiration');
      localStorage.removeItem('impersonate');
      localStorage.removeItem('affiliateImpersonate');
      localStorage.removeItem('locale');
      localStorage.removeItem('recentlyUsedColors');
      localStorage.removeItem('upgrade_modal_showed');
      localStorage.removeItem(WIZARD_SETUP_KEY);

      sessionStorage.removeItem('campaignFilter');
      failedIntegrationResyncStorage.deleteResyncPollTimestamp();

      const layer = window.dataLayer || [];
      layer.push({ event: 'Logout' });

      setTimeout(() => {
        const to = `/login/${state.locale === 'hu' ? 'hu' : 'en'}`;
        this.commit('resetState');
        if (redirect) {
          window.location = to;
        }
      }, 50);
    },
    setLocale(state, locale) {
      _setLocale(state, locale);
    },
    setCurrentPlan(state, plan) {
      state.currentPlan = plan.toUpperCase();
    },
    setCurrentPeriod(state, period) {
      state.currentPeriod = period;
    },
    skipWizard(state) {
      state.account.settings.wizardPreferences.skip = true;
    },
    setBankTransferToBraintreeNotification(state, status) {
      state.showBankTransferToBraintreeNotification = status;
    },
    setBankTransferNotification(state, status) {
      state.showBankTransferNotification = status;
    },
    setAccount(state, account) {
      state.account = _clone(account);
      state.accountType = state.account.type;
      setAccountIdInCookie(account.databaseId);
      const dataUser = {
        id: account.databaseId,
        currentPackage: account.billing.package,
        email: account.login.email,
        businessName: account.businessName,
        firstName: account.login.firstName,
        lastName: account.login.lastName,
        region: account.login.region,
        userHash: account.userHash,
        createdAt: new Date(account.login.createdAt).getTime() / 1000,
        dateCreatedAt: new Date(account.createdAt).getTime() / 1000,
        isSuperAdmin: account.isSuperAdmin,
        locale: account.login.locale,
        profile: account.profile || {
          firstConversion: null,
        },
      };

      addProfileValues(dataUser.profile);

      if (account.settings && account.settings.shops) {
        const shops = account.settings.shops;
        const planSet = new Set();
        shops.forEach((s) => {
          if (s.type && s.type.length > 1) {
            const uppercaseType = capitalizeFirstLetter(s.type);
            dataUser[`is${uppercaseType}`] = true;
          }

          if (s.plan_name && s.plan_name.length > 1) {
            planSet.add(s.plan_name);
          }
        });

        const isShopifyTrial = planSet.size === 1 && planSet.has('trial');
        dataUser.isShopifyTrial = isShopifyTrial;

        addAppInstalledAgo(shops.filter((shop) => shop.type === 'shopify'));
      }

      const domains = _get(account, 'settings.domains', []);
      if (domains) {
        const isKlaviyo = domains.some(
          (domain) => domain && _get(domain, 'isKlaviyoDetected', false),
        );
        dataUser.isKlaviyo = isKlaviyo;
      }

      if (typeof window.dataLayer !== 'object') {
        window.dataLayer = [];
      }

      window.dataLayer.push({
        event: 'User loaded',
        user: dataUser,
      });

      const logRocketProject = runtimeConfig.VUE_APP_LOGROCKET_PROJECT;
      const logRocketInited = window._logRocketInited;
      const logRocketIdentified = window._logRocketIdentified;
      if (logRocketProject && logRocketInited && !logRocketIdentified) {
        window._logRocketIdentified = true;
        if (account.isSuperAdmin === true) {
          LogRocket.track('superadmin');
        }

        setTimeout(() => {
          LogRocket.identify(dataUser.id.toString(), {
            id: dataUser.id,
            email: dataUser.email,
            package: dataUser.package,
            region: dataUser.region,
            locale: dataUser.locale,
          });
          if (window.heap) {
            LogRocket.getSessionURL(function (sessionURL) {
              window.heap.track('LogRocket', { sessionURL });
            });
          }
        }, 500);
      }

      setBusinessType(account.settings.qualifications.business);
    },

    checkLocale(state) {
      const storageLocal = localStorage.getItem('locale');
      if (storageLocal !== 'en' || storageLocal !== 'hu') {
        const locale = _get(state, 'account.login.locale') || 'en';
        state.locale = locale;
        localStorage.setItem('locale', locale);
        i18n.locale = locale;
        moment.locale(locale);
      }
    },
    resetState(state, type) {
      let inital = {};

      switch (type) {
        case 'admin':
          inital = adminInitialState;
          break;
        case 'editor':
          inital = editorInitialState;
          break;
        default:
          inital = { ...adminInitialState, ...editorInitialState };
      }

      for (const key in inital) {
        state[key] = inital[key];
      }
    },
    setInsertCodeStatus(state, status) {
      state.isCodeInserted = status;
    },
    hideWelcomeMessage(state) {
      state.account.settings.onboarding.showWelcomeMessage = false;
    },
    // admin end
    setStateAttr(state, payload) {
      let { attr, value } = payload;
      if (attr === 'paneLevel' && value === 1 && state.templateSaveData.type === 'base') {
        this.commit('setSelectedElement', getElement(state.selectedPage.uid));
        value = 2;
      }
      _vm().$set(state, attr, value);
    },
    saveThemeKit(state) {
      const colors = state.globalStyle.palette;
      const oldName = this.getters.template?.themeKit?.name;
      const hasLogo = !!state.themeKit?.logo;
      const isSameLogo = state.themeKit?.logo?.current === state.savedThemeKit?.logo?.current;

      const themeKit = {
        ...state.themeKit,
        colors,
        changed:
          state.isThemekitHidden ||
          state.themekitChanged ||
          state.elementStylesChanged ||
          (hasLogo && !isSameLogo),
      };

      _saveThemeKit(themeKit, oldName);
    },
    previewCss(state, css) {
      window.om.bus.$emit('setPreviewCss', css);
    },
    resetCustomCss() {
      window.om.bus.$emit('resetCustomCss');
    },
    deselectAll() {
      window.om.bus.$emit('deselectAll');
    },
    activatePaneL2(state, payload) {
      _activatePaneL2(state, payload, _vm());
    },
    activatePaneL3(state, payload) {
      state.paneLevel = 3;
      state.paneL3 = payload;
    },
    backPane(state) {
      state.paneLevel -= 1;
      state.paneL3 = null;
      if (state.paneLevel < 2) state.paneL2 = null;
    },
    updateStyle(state, { property, value }) {
      _updateStyle(state, property, value);
    },
    updateData(state, payload) {
      const { property, value } = payload;

      nestedAccess(state, property, value);
      const stateAttr = property.split('.')[0];
      const uid = state[stateAttr].uid;
      let data = null;

      if (stateAttr === 'data') {
        data = _clone(state.data);
      } else {
        data = _clone(state[stateAttr].data);
      }

      window.om.bus.$emit('updateData', { uid, data });
      frameStore().commit('customTheme/modifyElementByUid', {
        uid,
        value,
      });
    },
    updateDataProperty(state, { uid, property, value }) {
      frameStore().commit('updateDataProperty', { uid, property, value });
    },
    updateValidationErrorProperty(state, property) {
      state.validationError.property = property;
    },
    handleColorInstanceToStore(state, colorInstance) {
      const isGlobalStyle = colorInstance.isGlobalStyle();
      const values = colorInstance.getValues();
      if (isGlobalStyle) {
        const clonedValues = _clone(values);
        clonedValues.forEach(({ prop, value }) => {
          setWith(state.globalStyle, prop, value, (ns, key, target) => {
            _vm().$set(target, key, ns);
          });
          window.om.bus.$emit('updateTemplateStyle', state.globalStyle);
          window.om.bus.$emit('generateCss');
        });
      } else {
        window.om.bus.$emit('historySave');
        const clonedValues = _clone(values);
        clonedValues.forEach(({ uid, prop, value }) => {
          setWith(getElement(uid), prop, value, (ns, key, target) => {
            _vm().$set(target, key, ns);
          });
          window.om.bus.$emit('updateElementStyle', uid);
        });
      }
    },
    updateWholeGlobalStyle(state, payload) {
      _vm().$set(state, 'globalStyle', payload);
    },
    updatePage(state, { uid, style, data }) {
      // state.selectedPage = index
      _vm().$set(state, 'page', { uid, style, data });
    },
    selectPage(_, pageId) {
      window.om.bus.$emit('selectPage', pageId);
    },
    showImageManager(state) {
      state.showImageManager = true;
    },
    showImageManagerV2(state) {
      state.showImageManagerV2 = true;
    },
    hideImageManager(state) {
      if (
        (state.selectedElement === 'OmImage' &&
          !state.selectedElement.desktop.background.imageId) ||
        (['OmProduct', 'OmScratchCard', 'OmPickAPresent'].includes(state.selectedElement) &&
          !state.selectedElement.data.image.imageId)
      ) {
        window.om.bus.$emit('removeElement', { uid: state.selectedElement.uid });
      }
      state.showImageManager = false;
    },
    hideImageManagerV2(state) {
      if (
        (state.selectedElement === 'OmImage' &&
          !state.selectedElement.desktop.background.imageId) ||
        (['OmProduct', 'OmScratchCard', 'OmPickAPresent'].includes(state.selectedElement) &&
          !state.selectedElement.data.image.imageId)
      ) {
        window.om.bus.$emit('removeElement', { uid: state.selectedElement.uid });
      }
      state.showImageManagerV2 = false;
    },
    setSelectedProductIndex(state, index) {
      state.selectedProductIndex = index;
    },
    saveProductDetails(state, index) {
      if (index === undefined) index = state.selectedProductIndex;
      const products = _clone(state.selectedElement.data.products);
      products[index] = _clone(state.productDetailsModal);
      _vm().$set(state.selectedElement.data, 'products', products);
    },
    setProduct(state, { index, product }) {
      if (index === undefined) index = state.selectedProductIndex;
      const products = _clone(state.selectedElement.data.products);
      products[index] = _clone(product);
      _vm().$set(state.selectedElement.data, 'products', products);
    },
    setMoneyFormat(state, format) {
      _vm().$set(state.selectedElement.data, 'moneyFormat', format);
    },
    hideImageCropper(state) {
      state.imageCropper.show = false;
    },
    setSelectedPath(state, property) {
      state.selectedPath = property;
    },
    setImageManagerDestination(state, property) {
      state.imageManagerDestination = property;
    },
    changeFormManagerVisibility(state, property) {
      if (property.hide !== undefined) {
        state.showFormManager[property.hide] = false;
      }
      if (property.show !== undefined) {
        state.showFormManager[property.show] = true;
      }
      if (property.selectedElementValue !== undefined) {
        state.selectedElementValue = property.selectedElementValue;
      }
      if (property.fields !== undefined) {
        state.inputFields = property.fields;
      }
      if (property.createDiscountProps !== undefined) {
        state.createDiscountProps = property.createDiscountProps;
      }

      if (property.meta) {
        state.formModalMetaData = property.meta;
      }
    },
    setSelectedPage(state, page) {
      state.selectedPage = page;

      if (page && page.isTeaser) {
        state.teaserPage = page;
      }
    },
    setTeaserPage(state, page) {
      state.teaserPage = page;
    },
    setSelectedElement(state, element) {
      if (element) {
        _activatePaneL2(state, getPaneByElementType(element), _vm());
      }

      state.selectedElement = element;
    },
    setSelectedRow(state, row) {
      state.selectedRow = row;
    },
    setSelectedColumn(state, column) {
      state.selectedColumn = column;
    },
    //   if (skipRowPane !== true) {
    //     _activatePaneL2(state, 'RowPane', _vm(), sameRow)
    //   }
    //   state.selectedRow = row
    //   const selectedColumn = columnIndex > -1 ? state.selectedRow.columns[columnIndex] : null
    //   state.selectedColumn = selectedColumn
    //   if (selectedColumn) {
    //     _vm().$bus.$emit('setWorkspaceStateAttr', { attr: 'selectedColumnId', value: selectedColumn.uid })
    //   }
    // },
    showColorPicker(
      state,
      {
        top,
        left,
        right,
        property,
        globalProperty,
        palette,
        source,
        value,
        alpha,
        onlySolid,
        index,
        colorInstance,
        component,
      },
    ) {
      state.colorPicker.show = true;
      state.colorPicker.top = `${top}px`;
      state.colorPicker.left = `${left}px`;
      state.colorPicker.right = right;
      state.colorPicker.property = property;
      state.colorPicker.palette = palette;
      state.colorPicker.input = property.includes('color2') ? 2 : 1;
      state.colorPicker.source = source;
      state.colorPicker.value = value;
      state.colorPicker.globalProperty = globalProperty;
      state.colorPicker.alpha = alpha;
      state.colorPicker.onlySolid = onlySolid;
      state.colorPicker.index = index;
      state.colorPicker.colorInstance = colorInstance;
      state.colorPicker.component = component;
      track(HEAP_EVENT_NAME_EDITOR_CHANGE, {
        component: getColorPickerComponent(component, property, state),
        setting: 'picker-open',
        change: property,
      });
    },
    setColorPickerProperty(state, payload) {
      if (state.colorPicker.property != payload) {
        track(HEAP_EVENT_NAME_EDITOR_CHANGE, {
          component: getColorPickerComponent(
            state.colorPicker.component,
            state.colorPicker.property,
            state,
          ),
          setting: 'picker-property',
          change: payload,
        });
      }
      state.colorPicker.property = payload;
    },
    hideColorPicker(state) {
      // if (state.colorPicker.show) {
      //   History.save()
      // }
      if (state.colorPicker.show) {
        track(HEAP_EVENT_NAME_EDITOR_CHANGE, {
          component: getColorPickerComponent(
            state.colorPicker.component,
            state.colorPicker.property,
            state,
          ),
          setting: 'picker-close',
          change: state.colorPicker.property,
        });
      }
      state.colorPicker.show = false;
    },
    changeCalendarVisibility(state, payload) {
      state.calendar.visible = payload === 'visible';
    },
    [RECENTLY_USED_COLOR_SUBSCRIBE](state, colors) {
      state.colorPicker.recentlyUsedColor = colors;
    },
    // TODO[sketchV2] OMC-2112 No need the proposeColor and usePresetColor after sketchV2
    proposeColor(state, value) {
      const { property } = state.colorPicker;
      if (property.startsWith('omcolor')) {
        // Quill uses SketchV2
        return;
      }
      if (property.startsWith('globalStyle')) {
        setWith(state, property, value, (ns, key, target) => {
          this._vm.$set(target, key, ns);
        });
      } else if (/^(selectedPage|selectedColumn|selectedRow)/.test(property)) {
        setWith(state, property, value, (ns, key, target) => {
          this._vm.$set(target, key, ns);
        });
        const [selectedProperty] = property.split('.');
        const uid = _get(state, `${selectedProperty}.uid`);
        if (uid) {
          window.om.bus.$emit('updateElementStyle', uid);
        }
      } else {
        // _updateStyle(state, property, value)
        if (
          _get(state.selectedElement, 'mobile.background.type') === null &&
          property === 'mobile.background.color'
        ) {
          this._vm.$set(
            state.selectedElement.mobile.background,
            'type',
            _get(state.selectedElement, 'desktop.background.type', 'solid'),
          );
        }
        setWith(state.selectedElement, property, value, (ns, key, target) => {
          this._vm.$set(target, key, ns);
        });
        setWith(getElement(state.selectedElement.uid), property, value, (ns, key, target) => {
          this._vm.$set(target, key, ns);
        });
        window.om.bus.$emit('updateElementStyle', state.selectedElement.uid);
      }
      window.om.bus.$emit('userInputChange', { property, value });
    },

    usePresetColor(state, data) {
      const { property, source } = state.colorPicker;
      const value = data?.index ?? 0;
      if (property.startsWith('omcolor')) {
        const propertyToUpdate = property.endsWith('class') ? property : `${property}class`;
        window.om.bus.$emit('wysiwygColorUpdate', {
          property: propertyToUpdate,
          value,
          source,
        });
      } else if (property.startsWith('globalStyle')) {
        setWith(state, property, value, (ns, key, target) => {
          this._vm.$set(target, key, ns);
        });
      } else if (/^(selectedPage|selectedColumn|selectedRow)/.test(property)) {
        setWith(state, property, value, (ns, key, target) => {
          this._vm.$set(target, key, ns);
        });
        const [selectedProperty] = property.split('.');
        const uid = _get(state, `${selectedProperty}.uid`);
        if (uid) {
          window.om.bus.$emit('updateElementStyle', uid);
        }
      } else {
        setWith(state.selectedElement, property, value, (ns, key, target) => {
          this._vm.$set(target, key, ns);
        });
        setWith(getElement(state.selectedElement.uid), property, value, (ns, key, target) => {
          this._vm.$set(target, key, ns);
        });
        window.om.bus.$emit('updateElementStyle', state.selectedElement.uid);
      }
      window.om.bus.$emit('userInputChange', { property, value });
    },
    updateSettings(_, values) {
      values.forEach(({ uid, prop, value }) => {
        setWith(getElement(uid), prop, value, (ns, key, target) => {
          _vm().$set(target, key, ns);
        });
        window.om.bus.$emit('updateElementStyle', uid);
      });
    },

    updateMainColor(state, main) {
      const [mainColor, ...secondaryColors] = getPaletteColors(main);

      _vm().$set(window.om.template, 'palette', [mainColor, ...secondaryColors]);
      _vm().$set(state.globalStyle.palette, 'mainColor', mainColor);
      _vm().$set(state.globalStyle.palette, 'secondaryColors', secondaryColors);

      window.om.bus.$emit('generateCss');
    },
    updateThemeColor(state, param) {
      const { themeColor, name, index } = param;

      if (!Object.values(state.modifiedBrandColors).includes(index)) {
        state.modifiedBrandColors.push(index);
      }

      const modified = [...state.globalStyle.palette.themeColors];
      modified[index] = { name, themeColor };

      _vm().$set(state.globalStyle.palette, `themeColors`, modified);
      window.om.bus.$emit('updateTemplateStyle', state.globalStyle);
      window.om.bus.$emit('generateCss');
    },
    updateCustomThemeRadius(state, { value }) {
      const workSpaceStore = frameStore();
      _vm().$set(state.themeKit, 'rounding', value);
      _vm().$set(workSpaceStore.state.template.themeKit, `rounding`, value);
      const elements = workSpaceStore.state.template.elements;
      elements.forEach((element) => {
        /* Might be a performance issue */
        window.om.bus.$emit('updateElementStyle', element.uid);
      });
    },
    updateThemeKitFont(state, newThemeKitFonts) {
      const workSpaceStore = frameStore();
      _vm().$set(workSpaceStore.state.template.themeKit, `fonts`, newThemeKitFonts);
      _vm().$set(state.themeKit, `fonts`, newThemeKitFonts);
      window.om.bus.$emit('generateCss');
    },
    acceptColor(state) {
      // ToDo add to pallette
      state.colorPicker.show = false;
    },
    updateEditedCampaignData(state, data) {
      Object.assign(state.lastEditedCampaignData, data);
    },
    setUserInfoMessage(state, data) {
      state.userInfoMessage = data;
    },
    setFonts(state, fonts) {
      state.fonts = _clone(fonts);
    },
    updateFont(state, payload) {
      const { key, subsets } = payload;
      state.fonts[key].installedSubsets = subsets;
      _vm().$bus.$emit('updateFont', payload);
    },
    setFontStatus(state, payload) {
      const { key, status } = payload;
      _vm().$set(state.fonts[key], 'status', status);
    },
    setWhiteLabelSettings(state, payload) {
      state.whiteLabelSettings = payload;
      _vm().$set(state, 'whiteLabelSettings', payload);
    },
    setTeaserText(state, payload) {
      _vm().$set(state.globalStyle.data, 'tabText', payload);
    },
    setTeaserVisibility(state, payload) {
      _vm().$set(state.globalStyle.tab.display, 'before', payload);
      _vm().$set(state.globalStyle.tab.display, 'after', payload);
    },
    hideAllModals() {
      _vm().$bus.$emit('hideAllModals');
    },
    addTemplateFeature(_, feature) {
      _vm().$bus.$emit('addTemplateFeature', feature);
    },
    removeTemplateFeature(_, feature) {
      _vm().$bus.$emit('removeTemplateFeature', feature);
    },
    confirmProductsMissing() {
      _vm().$bus.$emit('setWorkspaceStateAttr', {
        attr: 'confirmedProductsMissing',
        value: true,
      });
    },
    setUTMParams(state, payload) {
      _vm().$set(state, 'utmParams', payload);
    },
    modifyElementByUid(_, { uid, value }) {
      frameStore().commit('customTheme/modifyElementByUid', {
        uid,
        value,
      });
    },
    changeThemeVersionForElement(_, { element, themeVersion, defaultStyle }) {
      frameStore().commit('customTheme/changeThemeVersionForElement', {
        element,
        themeVersion,
        defaultStyle,
      });
    },
    setThemeKitName(state, value) {
      state.themeKit.name = value;
    },
    setIsCustomThemeNameError(state, value) {
      state.isCustomThemeNameError = value;
    },
    setPreferredTemplateLanguage(state, language) {
      state.preferredTemplateLanguage = language;
    },
    setTemplateChooserFilterValues(state, value) {
      state.templateChooser.filterValues = value;
    },
    setBaseThemeKitIds(state, value) {
      state.templateChooser.baseThemeKitIds = value;
    },
    flagAccountAsAgency(state) {
      state.account.profile.agencyPotential = true;
    },
    setPartnerTypeService(state, service) {
      state.partnerTypeService = service;
    },
    hideStickyBar(state, value) {
      state.hideStickyBar = value;
    },
    setImageManagerLogoUpload(state, value) {
      state.imageManagerLogoUpload = value;
    },
  },
  actions: {
    changeImageOnPages(_, payload) {
      frameStore().dispatch('changeImageOnPages', payload);
    },
    saveImageReplaceValue(_, value) {
      _debouncedUpsertGeneralSetting(KEY_GENERAL_SETTING_IMAGE_REPLACE, value ? '1' : '0');
    },
    isImageUsedElsewhere(_, payload) {
      return frameStore().dispatch('isImageUsedElsewhere', payload);
    },
    async fetchDomains() {
      const {
        data: { accountDomains },
      } = await apolloClient.query({
        query: GET_DETAILED_DOMAINS,
      });
      this.commit('setDomains', accountDomains);
    },
    async fetchRecommendedTactics({ state }) {
      if (state.recommendedTactics.length === 0) {
        const {
          data: { useCases },
        } = await apolloClient.query({
          query: GET_RECOMMENDED_USE_CASES_V2,
        });
        this.commit('setRecommendedTactics', useCases);
      }
    },
    async fetchThemeStyles() {
      const { themeDefaults, themeOptions } = await frameStore().dispatch(
        'customTheme/fetchThemeStyles',
      );
      return { themeDefaults, themeOptions };
    },
    async login(
      { state, dispatch },
      { expiration, locale, impersonate, embedded, affiliateImpersonate },
    ) {
      const reLogin = state.loggedIn === true;

      impersonate = impersonate || false;
      affiliateImpersonate = affiliateImpersonate || false;
      // apolloClient.cache.reset()
      state.loggedIn = true;
      state.impersonate = impersonate;
      state.affiliateImpersonate = affiliateImpersonate;
      // locale = 'en' // force english locale
      _setLocale(state, locale);
      localStorage.setItem('cookieExpiration', `${expiration}`);
      localStorage.setItem('impersonate', impersonate);
      localStorage.setItem('affiliateImpersonate', affiliateImpersonate);
      state.embedded = embedded || false;

      _emitLogin(this);

      if (reLogin) {
        await dispatch('fetchAccount');
      }

      await Promise.all([
        dispatch('payment/loadBilling'),
        dispatch('payment/loadDeclinedPaymentDetail'),
        dispatch('payment/fetchFlexiPayDetail'),
      ]);
    },
    async autoLogin({ commit, dispatch }, redirect = true) {
      commit('showAdminLoader', true);
      try {
        const { data } = await axios.get(`${apiBaseUrl}/login/isAuthenticated`, {
          withCredentials: true,
        });
        commit('setPendingShopifyInstall', data?.data?.pendingShopifyInstall ?? null);
        if (data.success) {
          if (data.data && data.data.isNewRegistration) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ event: 'Trial end', region: data.data.region });
          }

          if (data.switchAccount) {
            commit('showAdminLoader', false);
            if (data?.data?.pendingShopifyInstall) {
              localStorage.setItem('om_redirect_after_switch_account', '/connect-shopify');
            } else if (
              !document.location.pathname.includes('login') &&
              !document.location.pathname.includes('switch-account')
            ) {
              localStorage.setItem(
                'om_redirect_after_switch_account',
                document.location.pathname + document.location.search,
              );
            }

            return router.replace({ name: 'switch_account' });
          }
          const reInstall = router.currentRoute.query.shopifyReinstall;
          const shopifyDomain = router.currentRoute.query.shopifyDomain;

          if (reInstall && shopifyDomain) {
            commit('setPendingShopifyInstall', null);
            const success = reInstall === 'true';
            router.push({
              name: 'dashboard',
              query: {
                confirm_shopify_install: success ? 'success' : 'failed',
                domain: shopifyDomain,
              },
            });
            commit('showAdminLoader', false);
            return;
          }

          if (data?.data?.pendingShopifyInstall) {
            commit('showAdminLoader', true);
            if (data.success) {
              dispatch('login', {
                ...data.data,
                affiliateImpersonate: localStorage.getItem('affiliateImpersonate') === 'true',
              });
            }

            router.replace({ name: 'connect_shopify' });

            // app needs time to set his own states, without this -> infinite loading
            return this.$nextTick(() => {
              commit('showAdminLoader', false);
            });
          }

          dispatch('login', {
            ...data.data,
            affiliateImpersonate: localStorage.getItem('affiliateImpersonate') === 'true',
          });
          if (redirect) {
            await showInitialScreenByUserType(data.data.accountType);
          }
        } else if (data?.error) {
          if (data.error === 'Account or login error') {
            commit('logout', true);
          }
        }
      } catch (e) {
        console.error('Failed to auto login:', e);
      }

      commit('showAdminLoader', false);
    },
    async logout({ commit }, { redirect = true } = {}) {
      await apolloClient.mutate({
        mutation: LOGOUT,
      });
      removeAccountIdFromCookie();
      SalesAdapter.sendLogout();
      commit('logout', redirect);
    },
    async loadInputFields({ state }) {
      const { type, value } = state.templateSaveData;

      const templateQuery = gql`{fields: allTemplateFields(template: "${value}"){_id, customId ,name, type}}`;
      const variantQuery = gql`
        {
          fields: allFields {
            _id
            customId
            name
            type
          }
        }
      `;

      try {
        const {
          data: { fields },
        } = await apolloClient.query({
          query: type === 'base' ? templateQuery : variantQuery,
        });

        const inputs = addInputDefaultOptions(_clone(fields), state.selectedElement);

        state.inputs = _clone(inputs);
      } catch (e) {
        console.log(e);
      }
    },
    async getMatchingInputs({ dispatch, state }) {
      await dispatch('loadInputFields');

      const selectedElement = state.selectedElement.data.form.customSettings;

      const isElementTypeMatching = (i) =>
        i &&
        i.type &&
        ((inputTypes.includes(i.type) && inputTypes.includes(selectedElement.type)) || // Is text input?
          (dropdownTypes.includes(i.type) && dropdownTypes.includes(selectedElement.type)) || // Is dropdown?
          selectedElement.type === i.type); // Is matching type

      return state.inputs.filter((i) => isElementTypeMatching(i));
    },
    async fetchAccount({ commit, dispatch }) {
      const {
        data: { account },
      } = await apolloClient.query({
        query: GET_ACCOUNT,
      });

      if (account) {
        commit('setAccount', account);
        commit('checkLocale');
        await dispatch('initPartnerTypeService');
        return account;
      }
      return null;
    },
    initPartnerTypeService({ state, commit }) {
      const partnerTypeService = new PartnerTypeService({
        accountType: state.account.type,
        partnerType: state.account.partnerType,
      });
      commit('setPartnerTypeService', partnerTypeService);
    },
    async getPublicFeatureFlags() {
      const {
        data: { publicFeatures },
      } = await apolloClient.query({
        query: GET_PUBLIC_FEATURES,
      });

      return publicFeatures;
    },
    async addOrRemoveFeatureFlagToUser({ dispatch }, { flag, active }) {
      let isPublic = false;
      try {
        const publicFeatureFlags = await dispatch('getPublicFeatureFlags');
        isPublic = publicFeatureFlags.find((feature) => feature.flag === flag);
      } catch (error) {
        console.error('Failed to get public feature flags:', error);
      }

      if (!isPublic) return false;

      await apolloClient.mutate({
        mutation: active ? ADD_FLAG_TO_ACCOUNT : REMOVE_FLAG_TO_ACCOUNT,
        variables: {
          flag,
        },
      });
      Cookies.set(flag, active);
    },
    async fetchInsertCodeStatus({ commit, getters, state }) {
      try {
        const {
          data: { codeInsertedInAllDomains },
        } = await apolloClient.query({
          query: CODE_INSERTED_IN_ALL_DOMAINS,
        });

        if (
          codeInsertedInAllDomains &&
          getters.currentPlan === 'FREE' &&
          state.account.limits.pageViews === 0
        ) {
          state.account.limits.pageViews = 1;
        }

        commit('setInsertCodeStatus', codeInsertedInAllDomains);
      } catch (e) {
        if (!e.networkError) {
          throw e;
        }
      }
    },
    setEditorPreviewMode({ state, commit }, { mode, reset = true }) {
      const isMobilePreview = mode === 'mobile';

      if (reset) {
        state.paneLevel = 1;
      }
      _vm().$bus.$emit('setWorkspaceStateAttr', {
        attr: 'editMode',
        value: true,
      });
      const hidden = isElementHiddenInView(state.selectedElement, mode);
      if (reset || hidden) {
        commit('deselectAll');
      }
      state.mobilePreview = isMobilePreview;
      _vm().$bus.$emit('setMobilePreview', isMobilePreview);

      if (isMobilePreview) {
        const mobileSettings = document.querySelector('.mobile-preview-accordion .h3-elem');
        if (mobileSettings) {
          const openedSettings = document.querySelectorAll('.accordion.angle-close');
          openedSettings.forEach((opened) => {
            const heading = opened.querySelector('.h3-elem');
            if (heading) heading.click();
          });
          mobileSettings.click();
        }
      }

      if (state.teaserPreview) {
        commit('activatePaneL2', 'TeaserPane');
      }

      const driver = productTour.state.driver;
      if (
        isMobilePreview &&
        driver &&
        driver.hasHighlightedElement() &&
        [
          document.querySelector('.device-selector'),
          document.querySelector('.device-selectors'),
        ].includes(driver.getHighlightedElement().node)
      ) {
        commit('selectPage', state.pages[0].uid);
        commit('setStateAttr', { attr: 'teaserPreview', value: false });
        _vm().$bus.$emit('setTeaserPreview', false);
        setTimeout(() => {
          _vm().$bus.$emit('advanceProductTour');
        }, 200);
      }
    },
    showImageCropper({ state }, { colorInstance, actionLocation }) {
      state.imageCropper.show = true;
      state.imageCropper.actionLocation = actionLocation;
      state.imageCropper.colorInstance = colorInstance;
    },
    oauthLogin() {
      try {
        if (
          (localStorage.getItem('shoprenter_oauth') || localStorage.getItem('shopify_oauth')) &&
          window.parent !== window
        ) {
          localStorage.removeItem('shoprenter_oauth');
          localStorage.removeItem('shopify_oauth');

          this.dispatch('fetchOAuthToken')
            .then((token) => this.dispatch('broadcastOAuthToken', token))
            .catch((err) => {
              console.error('Failed to trigger oauth success login:', err);
            });
        }
      } catch (err) {
        console.error('failed oauth login flow', err);
      }
    },
    async fetchOAuthToken() {
      const {
        data: { token },
      } = await apolloClient.query({
        query: GET_OAUTH_TOKEN,
      });

      if (!token) {
        throw new Error('OAuth login token missing from response.');
      }

      return token;
    },
    broadcastOAuthToken(_, token) {
      const payload = { type: 'optimonk', action: 'login', token };
      const opener = window.opener || window.parent;

      if (opener) {
        opener.postMessage(JSON.stringify(payload), '*');
      }
    },
    async fetchSharedAccounts({ state }) {
      const {
        data: {
          availableAccountsResponse: { login, accounts },
        },
      } = await apolloClient.query({
        query: GET_AVAILABLE_ACCOUNTS,
      });

      state.sharedAccounts = accounts;

      return login;
    },
    async finishOnboarding({ state }) {
      const {
        data: { finishOnboarding },
      } = await apolloClient.mutate({
        mutation: FINISH_ONBOARDING,
      });
      if (finishOnboarding) {
        state.account.settings.onboarding.finished = true;
      }
    },
    async fetchCouponCounts({ state, getters }) {
      const campaignId = getters.campaignInnerId;
      state.couponCounts = { available: 0, shown: 0, reserved: 0 };
      if (campaignId) {
        const {
          data: { couponCount },
        } = await apolloClient.query({
          query: GET_COUPON_COUNT,
          variables: { campaignId },
        });

        state.couponCounts = {
          available: couponCount.availableCount,
          shown: couponCount.shownCount,
          reserved: couponCount.reservedCount,
        };
      }
    },
    async saveOnboardingStage({ state }, stage) {
      const onboardingFinished = _get(state, 'account.settings.onboarding.finished');

      const {
        data: { saveOnboardingStage },
      } = await apolloClient.mutate({
        mutation: SAVE_ONBOARDING_STAGE,
        variables: {
          stage: onboardingFinished ? null : stage,
        },
      });

      if (saveOnboardingStage) {
        state.account.settings.onboarding.stage = onboardingFinished ? null : stage;
      }
    },
    updateDeviceType({ state }) {
      state.device = window.innerWidth < 767 ? 'mobile' : 'other';
    },
    async trackPageview() {
      const url = window.location.href;
      if (!url) return;
      const {
        data: { result },
      } = await apolloClient.mutate({
        mutation: TRACK_PAGEVIEW,
        variables: { url },
      });

      return result;
    },
    emitAccountFeatures({ state }) {
      const features = _get(state, 'account.features', []);
      _setAccountFeaturesInWorkspace(features);
    },
    saveAutomaticCouponConfig(_, { elements = [], variantId = null } = {}) {
      const store = frameStore();

      if (!elements.length) {
        elements = [store.state.selectedElement];
      }

      if (!variantId) {
        variantId = store.state.variantId;
      }

      elements.forEach((element) => {
        const {
          type,
          prefix,
          fixedValue,
          percentageValue,
          expiration,
          expirationType,
          expiresIn,
          expiresInUnit,
          expirationTime,
        } = element.data.coupon.automatic;

        const variables = {
          variantId,
          elementId: element.uid.replace(/^ele_/, ''),
          type,
          prefix,
          value: type === 'fixed' ? parseFloat(fixedValue) : parseFloat(percentageValue),
          expiration,
          expirationType,
        };

        if (expirationType === 'absolute') {
          variables.expiresAt = expirationTime;
        } else {
          const multipliers = {
            day: 86400,
            hour: 3600,
            minute: 60,
          };
          variables.expiresIn = parseInt(expiresIn * multipliers[expiresInUnit], 10);
        }

        apolloClient.mutate({
          mutation: SET_AUTOMATIC_COUPON_CONFIG,
          variables,
        });
      });
    },
    removeAutomaticCouponConfig(_, { elements, variantId }) {
      const store = frameStore();

      if (!variantId) {
        variantId = store.state.variantId;
      }

      if (!elements) {
        return apolloClient.mutate({
          mutation: REMOVE_AUTOMATIC_COUPON_CONFIG_FOR_VARIANT,
          variables: { variantId },
        });
      }

      elements.forEach(function (element) {
        const variables = {
          variantId,
          elementId: element.uid.replace(/^ele_/, ''),
        };
        apolloClient.mutate({
          mutation: REMOVE_AUTOMATIC_COUPON_CONFIG,
          variables,
        });
      });
    },
    async setInfoBoxVisibility({ state }, { box, hide }) {
      await apolloClient.mutate({
        mutation: STORE_INFO_BOX_VISIBILITY,
        variables: { box, hide },
      });

      state.account.profile.infoBox = { ...state.account.profile.infoBox, [box]: hide };
    },
    async fetchPossibleFilterValues({ state, commit }) {
      if (state.templateChooser.filterValues) return;
      try {
        const {
          data: { values },
        } = await apolloClient.query({
          query: GET_POSSIBLE_FILTER_VALUES,
        });

        commit('setTemplateChooserFilterValues', values);
      } catch (e) {
        console.log("can't load filter values");
      }
    },
    async createDCCampaign(
      _,
      { url, name = '', addControlVariant = true, needCacheChanges = false },
    ) {
      try {
        const {
          data: { result },
        } = await apolloClient.query({
          query: CREATE_DC_CAMPAIGN_FROM_CACHE,
          variables: {
            input: { name, url, addControlVariant, needCacheChanges },
          },
        });

        return result;
      } catch (e) {
        console.log("can't create campaign");
      }

      return false;
    },
    showColorPicker(
      { state, commit },
      {
        calculateInlineElement = false,
        calculateTopPosition = false,
        calculateSidebarPosition = false,
        top: elementTop,
        left: elementLeft,
        ...data
      },
    ) {
      let top = elementTop;
      let left = elementLeft;

      if (calculateInlineElement) {
        const { top: calculatedTop, left: calculatedLeft } = _colorPickerInlinePosition({
          top: elementTop,
          left: elementLeft,
          isMobilePreview: state.mobilePreview,
        });
        top = calculatedTop;
        left = calculatedLeft;
      }

      if (calculateTopPosition) {
        top = _colorPickerCommonTopPosition(elementTop, false);
      }

      if (calculateSidebarPosition) {
        top = _colorPickerCommonTopPosition(elementTop, false);
        const calculatedLeft = elementLeft - W_PX_COLOR_PICKER - W_PX_COLOR_PICKER_PAD;
        left = calculatedLeft < 0 ? W_PX_COLOR_PICKER_PAD : calculatedLeft;
      }

      commit('showColorPicker', { ...data, top, left });
    },
    async validateElements(state, pageId = 'all') {
      return frameStore().dispatch('validateElements', pageId);
    },

    handleLogoDelete(_, payload) {
      return frameStore().dispatch('handleLogoDelete', payload);
    },
  },
});

store.subscribe((mutation) => {
  if (mutation.type === RECENTLY_USED_COLOR_SUBSCRIBE) {
    lsStore('recentlyUsedColors', mutation.payload);
  }
});

export default store;
